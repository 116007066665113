import request from '../utils/request'

export const login = (query) => {
    return request({
        url: 'login/login',
        method: 'post',
        data: query,
    })
}

export const logout = () => {
    return request({
        url: 'login/logout',
        method: 'post',
    })
}

export const myInfo = () => {
    return request({
        url: 'login/me',
        method: 'get',
    })
}
//選單列表
export const menuList = () => {
    return request({
        url: '/adminCore/menu/',
        method: 'get',
    })
}
//判斷路由
export const adminPermission = (query) => {
    return request({
        url: '/adminCore/adminPermission/',
        method: 'get',
        params: query,
    })
}
//商戶列表
export const merchantList = (query) => {
    return request({
        url: '/merchant/merchant/',
        method: 'get',
        params: query,
    })
}
//商戶列表-新增商戶
export const merchantCreate = (query) => {
    return request({
        url: '/merchant/merchant/create',
        method: 'post',
        data: query,
    })
}
//商戶列表-修改資料
export const merchantUpdataList = (query) => {
    return request({
        url: '/merchant/merchant/update',
        method: 'get',
        params: query,
    })
}
//商戶列表-修改商戶
export const merchantUpdata = (query) => {
    return request({
        url: '/merchant/merchant/update',
        method: 'post',
        data: query,
    })
}
//商戶列表-刪除
export const merchantDelete = (query) => {
    return request({
        url: '/merchant/merchant/delete',
        method: 'post',
        data: query,
    })
}
//商戶列表-啟用
export const merchantChangeStatus = (query) => {
    return request({
        url: '/merchant/merchant/changeStatus',
        method: 'post',
        data: query,
    })
}
//商戶明細
export const merchantDetail = (query) => {
    return request({
        url: '/merchant/merchant/detail',
        method: 'get',
        params: query,
    })
}
//域名來源列表
export const merchantAccount = (query) => {
    return request({
        url: '/merchant/merchantAccount',
        method: 'get',
        params: query,
    })
}
//域名來源列表-新增頁面選項
export const domainSelectList = () => {
    return request({
        url: '/merchant/merchantAccount/create',
        method: 'get',
    })
}
//域名來源列表-新增
export const domainSourceCreate = (query) => {
    return request({
        url: '/merchant/merchantAccount/create',
        method: 'post',
        data: query,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
//取得驗證碼
export const validateCode = (query) => {
  return request({
      url: '/merchant/merchantAccount/validateCode',
      method: 'get',
      params: query,
  })
}
//域名來源列表-修改頁面資料
export const domainSourceUpdateList = (query) => {
    return request({
        url: '/merchant/merchantAccount/update',
        method: 'get',
        params: query,
    })
}
//域名來源列表-修改
export const domainSourceUpdate = (query) => {
    return request({
        url: '/merchant/merchantAccount/update',
        method: 'post',
        data: query,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
//域名來源列表-刪除
export const domainSourceDelete = (query) => {
    return request({
        url: '/merchant/merchantAccount/delete',
        method: 'post',
        data: query,
    })
}
//域名來源列表-批量增加
export const domainSourcebatchImport = (query) => {
    return request({
        url: '/merchant/merchantAccount/batchImport',
        method: 'post',
        data: query,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
//域名來源列表-範本下載
export const domainSourceExport = () => {
    return request({
        url: '/merchant/merchantAccount/export',
        method: 'get',
        responseType: 'blob',
    })
}
//獲取同步失敗的域名
export const syncDomainFailed = () => {
    return request({
        url: '/merchant/merchantAccount/syncDomainFailed',
        method: 'get',
    })
}
//網域管理-網域列表
export const domainList = (query) => {
    return request({
        url: '/domain/domain',
        method: 'get',
        params: query,
    })
}
//網域管理-匯出網域列表
export const exportDomainList = (query) => {
    return request({
        url: '/domain/domain/exportDomainList',
        method: 'get',
        responseType: 'blob',
        params: query,
    })
}
//網域管理-取得網域來源清單
export const domainSourceList = (query) => {
    return request({
        url: '/domain/domain/source',
        method: 'get',
        params: query,
    })
}

//網域管理-手動同步DNS Nameserver  Namecheap
export const syncDNSNameserver = (query) => {
    return request({
        url: '/domain/domain/syncDNSNameserver',
        method: 'post',
        data: query,
    })
}
//網域管理-手動同步DNS Nameserver  NameSilo
export const syncDomainInfo = (query) => {
    return request({
        url: '/domain/domain/syncDomainInfo',
        method: 'post',
        data: query,
    })
}

//快速查詢DEMO/api/admin/domain/quickSearch/
export const quickSearch = (query) => {
    return request({
        url: '/domain/quickSearch',
        method: 'post',
        data: query,
    })
}
//商戶名細-分享域名給其他商戶&&查詢商戶名稱
export const shareDomain = (query) => {
    return request({
        url: '/merchant/merchant/shareDomain',
        method: 'post',
        data: query,
    })
}
//商戶名細-刪除分享商戶
export const deleteShareDomain = (query) => {
    return request({
        url: '/merchant/merchant/deleteShareDomain',
        method: 'post',
        data: query,
    })
}
//商戶名細-域名分享批量新增
export const shareDomainbatchImport = (query) => {
    return request({
        url: '/merchant/merchant/batchImport',
        method: 'post',
        data: query,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
//商戶名細-域名分享範本下載
export const shareDomainExport = () => {
    return request({
        url: '/merchant/merchant/export',
        method: 'get',
        responseType: 'blob',
    })
}
//商戶名細-網域新增標籤
export const createDomainTag = (query) => {
    return request({
        url: '/merchant/merchant/createDomainTag',
        method: 'post',
        data: query,
    })
}
//商戶名細-網域刪除標籤
export const deleteDomainTag = (query) => {
    return request({
        url: '/merchant/merchant/deleteDomainTag',
        method: 'post',
        data: query,
    })
}
//網域標籤管理-網域標籤列表-列表
export const tagList = (query) => {
    return request({
        url: '/tag/tag',
        method: 'get',
        params: query,
    })
}
//網域標籤管理-網域標籤列表-新增確認
export const tagCreate = (query) => {
    return request({
        url: '/tag/tag/create',
        method: 'post',
        data: query,
    })
}
//網域標籤管理-網域標籤列表-修改頁面
export const tagEditData = (query) => {
    return request({
        url: '/tag/tag/update',
        method: 'get',
        params: query,
    })
}
//網域標籤管理-網域標籤列表-修改確認
export const tagEdit = (query) => {
    return request({
        url: '/tag/tag/update',
        method: 'post',
        data: query,
    })
}

//網域標籤管理-網域標籤列表-刪除&批量刪除
export const tagDelete = (query) => {
    return request({
        url: '/tag/tag/delete',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-帳號列表

export const adminUsersList = (query) => {
    return request({
        url: '/auth/adminUsers',
        method: 'get',
        params: query,
    })
}

//後臺管理-後臺管理-帳號列表-新增頁面(get)
export const adminUsersCreateInfo = () => {
    return request({
        url: '/auth/adminUsers/create',
        method: 'get',
    })
}
//後臺管理-後臺管理-帳號列表-新增頁面(post))
export const adminUsersCreate = (query) => {
    return request({
        url: '/auth/adminUsers/create',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-帳號列表-修改頁面資料
export const adminUsersUpdateInfo = (query) => {
    return request({
        url: '/auth/adminUsers/update',
        method: 'get',
        params: query,
    })
}
//後臺管理-後臺管理-帳號列表-修改
export const adminUsersUpdate = (query) => {
    return request({
        url: '/auth/adminUsers/update',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-帳號列表-刪除
export const adminUsersDelete = (query) => {
    return request({
        url: '/auth/adminUsers/delete',
        method: 'post',
        data: query,
    })
}

//後臺管理-後臺管理-角色列表
export const adminAuthList = () => {
    return request({
        url: '/auth/adminAuth',
        method: 'get',
    })
}
//後臺管理-後臺管理-角色列表-新增頁面
export const adminAuthCreateList = () => {
    return request({
        url: '/auth/adminAuth/create',
        method: 'get',
    })
}
//後臺管理-後臺管理-角色列表-新增確認
export const adminAuthCreate = (query) => {
    return request({
        url: '/auth/adminAuth/create',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-角色列表-修改頁面
export const adminAuthUpdatePage = (query) => {
    return request({
        url: '/auth/adminAuth/update',
        method: 'get',
        params: query,
    })
}
//後臺管理-後臺管理-角色列表-修改確認
export const adminAuthUpdate = (query) => {
    return request({
        url: '/auth/adminAuth/update',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-告警設定
export const alertData = () => {
    return request({
        url: '/auth/alert',
        method: 'get',
    })
}
//後臺管理-後臺管理-告警設定-新增遮蔽域名
export const addAlertDomain = (query) => {
    return request({
        url: '/auth/alert/addAlertDomain',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-告警設定-刪除遮蔽域名
export const deleteAlertDomain = (query) => {
    return request({
        url: '/auth/alert/deleteAlertDomain',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-告警設定-新增機器人
export const alertAddRobot = (query) => {
    return request({
        url: '/auth/alert/addRobot',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-告警設定-刪除機器人
export const alertDeleteRobot = (query) => {
    return request({
        url: '/auth/alert/deleteRobot',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-告警設定-修改頁面
export const alertUpdatePage = (query) => {
    return request({
        url: '/auth/alert/update',
        method: 'get',
        params: query,
    })
}
//後臺管理-後臺管理-告警設定-修改確認
export const alertUpdate = (query) => {
    return request({
        url: '/auth/alert/update',
        method: 'post',
        data: query,
    })
}
//後臺管理-後臺管理-告警設定-修改確認
export const groupSearch = (query) => {
    return request({
        url: '/auth/alert/groupSearch',
        method: 'get',
        params: query,
    })
}

//後臺管理-商戶管理-續費列表(顯示90天內到期域名)
export const renewList = (query) => {
    return request({
        url: '/merchant/renew',
        method: 'get',
        params: query,
    })
}
//後臺管理-商戶管理-手動續費單一域名
export const renewManual = (query) => {
    return request({
        url: '/merchant/renew',
        method: 'post',
        data: query,
    })
}
//後臺管理-商戶管理-紀錄本次手動續費所有項目
export const renewRecord = (query) => {
    return request({
        url: '/merchant/renew/record',
        method: 'post',
        data: query,
    })
}
//後臺管理-商戶管理-單一域名變更自動續費
export const renewSetting = (query) => {
    return request({
        url: '/merchant/renew/setting',
        method: 'post',
        data: query,
    })
}
//後臺管理-商戶管理-手動續費記錄列表
export const manualRenewList = (query) => {
    return request({
        url: '/merchant/renew/history',
        method: 'get',
        params: query,
    })
}
//後臺管理-商戶管理-手動續費記錄的詳細資料
export const manualRenewListDetail = (query) => {
    return request({
        url: '/merchant/renew/history/detail',
        method: 'get',
        params: query,
    })
}
//後臺管理-商戶管理-自動續費記錄列表
export const automaticRenewList = (query) => {
    return request({
        url: '/merchant/renew/settingHistory',
        method: 'get',
        params: query,
    })
}
//後臺管理-商戶管理-匯出手動續費記錄列表至CSV
export const manualRenewListExport = (query) => {
    return request({
        url: '/merchant/renew/history/export',
        method: 'post',
        responseType: 'blob',
        data: query,
    })
}
//後臺管理-商戶管理-匯出自動續約變更記錄列表至CSV
export const automaticRenewListExport = (query) => {
    return request({
        url: '/merchant/renew/settingHistory/export',
        method: 'post',
        responseType: 'blob',
        data: query,
    })
}
//後臺管理-商戶管理-將域名加進購物車內
export const addCart = (query) => {
    return request({
        url: '/merchant/renew/addcart',
        method: 'post',
        data: query,
    })
}
//後臺管理-商戶管理-訂單列表
export const merchantOrder = (query) => {
    return request({
        url: '/merchant/order',
        method: 'get',
        params: query,
    })
}
//後臺管理-商戶管理-匯出訂單紀錄CSV
export const merchantOrderExport = (query) => {
    return request({
        url: '/merchant/order/export',
        method: 'post',
        responseType: 'blob',
        data: query,
    })
}
//後臺管理-商戶管理-商戶明細-啟動/關閉域名同步
export const syncDomain = (query) => {
    return request({
        url: '/merchant/merchant/syncDomain',
        method: 'post',
        data: query,
    })
}
//後臺管理-商戶管理-商戶明細-域名同步狀態是否在更新
export const checkInterruptCode = (query) => {
    return request({
        url: '/global/checkInterruptCode',
        method: 'get',
        params: query,
    })
}

//後臺管理-商戶管理-商戶明細-取消域名同步
export const deleteInterruptCode = (query) => {
    return request({
        url: '/global/deleteInterruptCode',
        method: 'post',
        data: query,
    })
}

//後臺管理-告警設定-群組通知測試
export const telegramTest = (query) => {
    return request({
        url: '/auth/alert/telegramTest',
        method: 'get',
        params: query,
    })
}

//後臺管理-取得匯率列表
export const exchangeRate = () => {
    return request({
        url: '/auth/exchangeRate',
        method: 'get',
    })
}
//後臺管理-取得指定匯率列表
export const exchangeRateById = (query) => {
    return request({
        url: '/auth/exchangeRate/update',
        method: 'get',
        params: query,
    })
}
//後臺管理-新增匯率
export const createExchangeRate = (query) => {
    return request({
        url: '/auth/exchangeRate/create',
        method: 'post',
        data: query,
    })
}
//後臺管理-修改匯率
export const updateExchangeRate = (query) => {
    return request({
        url: '/auth/exchangeRate/update',
        method: 'post',
        data: query,
    })
}
//後臺管理-刪除匯率
export const deleteExchangeRate = (query) => {
    return request({
        url: '/auth/exchangeRate/delete',
        method: 'post',
        data: query,
    })
}
//商戶名細-匯入域名標籤範本下載
export const exportTagSample = () => {
    return request({
        url: '/merchant/merchant/exportTagSample',
        method: 'get',
        responseType: 'blob',
    })
}

//商戶名細-匯出域名及當前含有標籤
export const exportTag = (query) => {
    return request({
        url: '/merchant/merchant/exportTag',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}

//商戶名細-域名標籤批量新增
export const batchImportDomainTag = (query) => {
    return request({
        url: '/merchant/merchant/batchImportDomainTag',
        method: 'post',
        data: query,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
    })
}

//取得加值服務總攬
export const valueAddedSummary = (query) => {
    return request({
        url: '/merchant/valueAdded/summary',
        method: 'get',
        params: query,
    })
}

//取得加值服務設定  IP加白: source_id=1  DNS管理: source_id=2
export const valueAdded = (query) => {
    return request({
        url: '/merchant/valueAdded/update',
        method: 'get',
        params: query,
    })
}

//編輯加值服務設定
export const valueAddedUpdate = (query) => {
    return request({
        url: '/merchant/valueAdded/update',
        method: 'post',
        data: query,
    })
}

//取得加值服務使用紀錄
export const valueAddedHistory = (query) => {
    return request({
        url: '/merchant/valueAdded/history',
        method: 'get',
        params: query,
    })
}

//加值服務-TG帳號/群組搜尋
export const valueAddedGroupSearch = (query) => {
    return request({
        url: '/merchant/valueAdded/groupSearch',
        method: 'get',
        params: query,
    })
}

//專案管理-專案列表
export const projectList = (query) => {
    return request({
        url: '/merchant/project',
        method: 'get',
        params: query,
    })
}
//專案管理-專案列表-新增專案
export const createProject = (query) => {
    return request({
        url: '/merchant/project/create',
        method: 'post',
        data: query,
    })
}

//專案管理-專案列表-修改頁面
export const updateProjectList = (query) => {
    return request({
        url: '/merchant/project/update',
        method: 'get',
        params: query,
    })
}
//專案管理-專案列表-修改確認
export const updateProject = (query) => {
    return request({
        url: '/merchant/project/update',
        method: 'post',
        data: query,
    })
}

//專案管理-專案列表-刪除
export const deleteProject = (query) => {
    return request({
        url: '/merchant/project/delete',
        method: 'post',
        data: query,
    })
}
//專案管理-專案列表-瀏覽專案
export const showProjectList = (query) => {
    return request({
        url: '/merchant/project/show',
        method: 'get',
        params: query,
    })
}
//圖表管理-圖表列表
export const chartList = (query) => {
    return request({
        url: '/merchant/chart',
        method: 'get',
        params: query,
    })
}
//圖表管理-圖表列表-新增圖表
export const createChart = (query) => {
    return request({
        url: '/merchant/chart/create',
        method: 'post',
        data: query,
    })
}

//圖表管理-圖表列表-修改頁面
export const updateChartList = (query) => {
    return request({
        url: '/merchant/chart/update',
        method: 'get',
        params: query,
    })
}
//圖表管理-圖表列表-修改確認
export const updateChart = (query) => {
    return request({
        url: '/merchant/chart/update',
        method: 'post',
        data: query,
    })
}
//圖表管理-圖表列表-刪除
export const deleteChart = (query) => {
    return request({
        url: '/merchant/chart/delete',
        method: 'post',
        data: query,
    })
}
//專案網域管理-專案網域列表
export const projectDomainList = (query) => {
    return request({
        url: '/merchant/projectDomain',
        method: 'get',
        params: query,
    })
}
//專案網域管理-專案網域列表-新增專案網域
export const createProjectDomain = (query) => {
    return request({
        url: '/merchant/projectDomain/create',
        method: 'post',
        data: query,
    })
}
//專案網域管理-專案網域列表-刪除
export const deleteProjectDomain = (query) => {
    return request({
        url: '/merchant/projectDomain/delete',
        method: 'post',
        data: query,
    })
}

//報表管理-域名對接總表
export const reportSummary = (query) => {
    return request({
        url: '/report/daily/summary',
        method: 'get',
        params: query,
    })
}

//報表管理-匯出域名對接總表
export const reportSummaryExport = (query) => {
    return request({
        url: '/report/daily/summary/export',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}
//報表管理-取得經銷商佣金報告
export const commission = (query) => {
    return request({
        url: '/report/daily/commission',
        method: 'get',
        params: query,
    })
}
//報表管理-取得客戶佣金報告
export const customerOrder = (query) => {
    return request({
        url: '/report/daily/customer-order',
        method: 'get',
        params: query,
    })
}
//報表管理-同步產品佣金報表
export const fetchCommission = (query) => {
    return request({
        url: '/report/daily/fetch-commissions',
        method: 'post',
        data: query,
    })
}
//報表管理-匯出佣金及客戶訂單報表
export const commissionCustomerOrderExport = (query) => {
    return request({
        url: '/report/daily/commission-and-customer-order/export',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}
//報表管理-當月續約到期列表
export const renewExpireList = (query) => {
    return request({
        url: '/report/renewExpire/list',
        method: 'get',
        params: query,
    })
}
//報表管理-匯出當月續約到期列表
export const renewExpireListExport = (query) => {
    return request({
        url: '/report/renewExpire/list/export',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}
//報表管理-歷史續約到期列表
export const renewExpireListHistory = (query) => {
    return request({
        url: '/report/renewExpire/listHistory',
        method: 'get',
        params: query,
    })
}
//報表管理-匯出歷史續約到期列表
export const renewExpireListExportHistory = (query) => {
    return request({
        url: '/report/renewExpire/list/exportHistory',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}
//報表管理-匯出Nicky 所需的報表
export const reportNickyExport = (query) => {
    return request({
        url: '/report/daily/nicky/export',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}
//報表管理-匯出每日存在DB內的周報表資料
export const reportNickyExportHistory = (query) => {
    return request({
        url: '/report/daily/nicky/export/history',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}
//網域管理-網域標記列表
export const domainMarkList = (query) => {
    return request({
        url: '/domain/domainMark/list',
        method: 'get',
        params: query,
    })
}
//網域管理-網域標記列表-新增確認
export const domainMarkCreate = (query) => {
    return request({
        url: '/domain/domainMark/create',
        method: 'post',
        data: query,
    })
}
//網域管理-網域標記列表-修改頁面
export const domainMarkUpdateList = (query) => {
    return request({
        url: '/domain/domainMark/update',
        method: 'get',
        params: query,
    })
}
//網域管理-網域標記列表-修改確認
export const domainMarkUpdate = (query) => {
    return request({
        url: '/domain/domainMark/update',
        method: 'post',
        data: query,
    })
}

//網域管理-網域標記列表-刪除
export const domainMarkDelete = (query) => {
    return request({
        url: '/domain/domainMark/delete',
        method: 'post',
        data: query,
    })
}
//網域管理-網域標記列表-批量匯入
export const domainMarkbatchImport = (query) => {
    return request({
        url: '/domain/domainMark/batchImport',
        method: 'post',
        data: query,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
//網域管理-網域標記列表-匯出
export const domainMarkListExport = (query) => {
    return request({
        url: '/domain/domainMark/list/export',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}
