<template>
    <div class="sidebar">
        <div class="bg-drawer"
             v-if="drawer"
             @click="handleClose"></div>
        <el-menu class="sidebar-el-menu drawer"
                 :class="drawer?'drawerClose':''"
                 :default-active="onRoutes"
                 :collapse="collapse"
                 background-color="#324157"
                 text-color="#bfcbd9"
                 active-text-color="#20a0ff"
                 unique-opened
                 router>
            <template v-for="(item,index) in items">
                <template v-if="item.child">
                    <el-submenu :index="index.toString()"
                                :key="index">
                        <template #title>
                            <i :class="item.web_icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.child">
                            <el-submenu v-if="subItem.child"
                                        :index="subItem.index"
                                        :key="subItem.web_link">
                                <template #title>{{ subItem.title }}</template>
                                <!-- 跳轉網址 -->
                                <el-menu-item v-for="(threeItem, i) in subItem.child"
                                              :key="i"
                                              class="open-window"
                                              @click="openWindow(threeItem.web_link)">{{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else
                                          @click="handleClose"
                                          :index="subItem.web_link"
                                          :key="subItem.web_link">{{ subItem.title }}</el-menu-item>
                        </template>
                        <!-- 跳轉網址 -->
                        <el-menu-item @click="openWindow('https://www.secureserver.net/products/domain-registration')"
                                      class="open-window"
                                      v-if="item.title=='商戶管理'">購買網域</el-menu-item>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.web_link"
                                  :key="item.web_link"
                                  @click="handleClose">
                        <i :class="item.web_icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
// import bus from "../common/bus";
import { menuList } from '../api/index'
export default {
    data() {
        return {
            items: [],
            windowsOpenMenu: {
                web_icon: 'el-icon-s-tools',
                index: 'tools',
                title: '網域管理員',
                child: [
                    // {
                    //     web_link: 'https://www.secureserver.net/products/domain-registration',
                    //     title: '購買網域',
                    // },
                    {
                        web_link: 'https://sso.secureserver.net/login?app=dcc&path=%2Fdomains&auth_reason=1',
                        title: 'clro網域管理',
                    },
                    {
                        web_link: 'https://sso.godaddy.com/login?app=dcc&path=%2Fdomains&auth_reason=1',
                        title: 'godaddy網域管理',
                    },
                ],
            },
        }
    },
    computed: {
        onRoutes() {
            if (this.$route.meta.activeMenu) {
                return this.$route.meta.activeMenu.replace('/', '')
            } else {
                return this.$route.path.replace('/', '')
            }
        },
        collapse() {
            return this.$store.state.collapse
        },
        drawer() {
            return this.$store.state.drawer
        },
    },
    mounted() {
        this.getMenu()
    },
    methods: {
        handleClose() {
            this.$store.commit('hadndleDrawer', !this.drawer)
        },
        getMenu() {
            menuList().then((res) => {
                if (res.code == 300) {
                    this.items = res.data.data_list
                    this.items[1].child.push(this.windowsOpenMenu)
                }
            })
        },
        openWindow(link) {
            window.open(link, '_blank', 'toolbar=yes')
        },
    },
}
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
.mobile .drawer {
    position: fixed;
    z-index: 999;
    top: 0;
    left: -250px;
    transition: left 0.3s ease-in-out;
    width: 250px;
}
.mobile .el-menu {
    border-right: 0;
}
.mobile .drawer.drawerClose {
    left: 0px;
}
.mobile .bg-drawer {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
}
.open-window {
    color: rgb(191, 203, 217) !important;
}
</style>
