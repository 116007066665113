import { createRouter, createWebHistory } from 'vue-router'
import { getCookie } from '../utils/cookie'
import Home from '../views/Home.vue'
import { adminPermission } from '../api/index'
const routes = [
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                meta: {
                    title: '系统首页',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */
                        '../views/Dashboard.vue'
                    ),
            },
            {
                path: '/userInformation',
                name: 'userInformation',
                meta: {
                    title: '基本資料',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "table" */
                        '../views/Commercial/Merchant/UserInformation/UserInformation.vue'
                    ),
            },
            {
                path: '/createCommercial',
                name: 'createCommercial',
                meta: {
                    title: '創建商戶',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "table" */
                        '../views/Commercial/Merchant/CreateCommercial.vue'
                    ),
            },
            {
                path: '/editCommercial',
                name: 'editCommercial',
                meta: {
                    title: '編輯商戶',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "table" */
                        '../views/Commercial/Merchant/EditCommercial.vue'
                    ),
            },
            {
                path: '/editDomain',
                name: 'editDomain',
                meta: {
                    title: '域名來源編輯',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "table" */
                        '../views/Commercial/Merchant/EditDomain.vue'
                    ),
            },
            {
                path: '/commercialList',
                name: 'commercialList',
                meta: {
                    title: '商戶列表',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "table" */
                        '../views/Commercial/Merchant/CommercialList.vue'
                    ),
            },
            {
                path: '/tgServices',
                name: 'tgServices',
                meta: {
                    title: '加值服務',
                },
                component: () => import('../views/Commercial/Merchant/TgServices/TgServices.vue'),
            },
            {
                path: '/projectList',
                name: 'projectList',
                meta: {
                    title: '專案列表',
                },
                component: () => import('../views/Commercial/Merchant/ProjectList/ProjectList.vue'),
            },
            {
                path: '/createProject',
                name: 'createProject',
                meta: {
                    title: '新增專案',
                },
                component: () => import('../views/Commercial/Merchant/ProjectList/CreateProject.vue'),
            },
            {
                path: '/editProject',
                name: 'editProject',
                meta: {
                    title: '編輯專案',
                },
                component: () => import('../views/Commercial/Merchant/ProjectList/EditProject.vue'),
            },
            {
                path: '/projectDetail',
                name: 'projectDetail',
                meta: {
                    title: '專案詳情',
                },
                component: () => import('../views/Commercial/Merchant/ProjectList/ProjectDetail.vue'),
            },
            {
                path: '/chartList',
                name: 'chartList',
                meta: {
                    title: '圖表管理',
                },
                component: () => import('../views/Commercial/Merchant/ProjectList/Chart/ChartList.vue'),
            },
            {
                path: '/createChart',
                name: 'createChart',
                meta: {
                    title: '新增圖表',
                },
                component: () => import('../views/Commercial/Merchant/ProjectList/Chart/CreateChart.vue'),
            },
            {
                path: '/editChart',
                name: 'editChart',
                meta: {
                    title: '編輯圖表',
                },
                component: () => import('../views/Commercial/Merchant/ProjectList/Chart/EditChart.vue'),
            },
            {
                path: '/renew',
                name: 'renew',
                meta: {
                    title: '續費管理',
                },
                component: () => import('../views/Commercial/Renew/Renew.vue'),
            },
            {
                path: '/renewRecord',
                name: 'renewRecord',
                meta: {
                    title: '續費紀錄',
                },
                component: () => import('../views/Commercial/Renew/RenewRecord.vue'),
            },
            {
                path: '/domainList',
                name: 'domainList',
                meta: {
                    title: '網域列表',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "table" */
                        '../views/Domain/DomainList.vue'
                    ),
            },
            {
                path: '/quickSearch',
                name: 'quickSearch',
                meta: {
                    title: '快速查詢',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "table" */
                        '../views/Domain/QuickSearch.vue'
                    ),
            },
            {
                path: '/tagList',
                name: 'tagList',
                meta: {
                    title: '網域標籤列表',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "table" */
                        '../views/Tag/TagList.vue'
                    ),
            },
            {
                path: '/createTag',
                name: 'createTag',
                meta: {
                    title: '新增網域標籤',
                    activeMenu: '/tagList',
                },
                component: () => import('../views/Tag/CreateTag.vue'),
            },
            {
                path: '/editTag',
                name: 'editTag',
                meta: {
                    title: '修改網域標籤',
                    activeMenu: '/tagList',
                },
                component: () => import('../views/Tag/EditTag.vue'),
            },
            {
                path: '/accountList',
                name: 'accountList',
                meta: {
                    title: '帳號列表',
                },
                component: () => import('../views/BackstageManagement/Account/AccountList.vue'),
            },
            {
                path: '/createAccount',
                name: 'createAccount',
                meta: {
                    title: '新增帳號',
                    activeMenu: '/accountList',
                },
                component: () => import('../views/BackstageManagement/Account/CreateAccount.vue'),
            },
            {
                path: '/editAccount',
                name: 'editAccount',
                meta: {
                    title: '編輯帳號',
                    activeMenu: '/accountList',
                },
                component: () => import('../views/BackstageManagement/Account/EditAccount.vue'),
            },
            {
                path: '/characterList',
                name: 'characterList',
                meta: {
                    title: '角色列表',
                },
                component: () => import('../views/BackstageManagement/Character/CharacterList.vue'),
            },
            {
                path: '/createCharacter',
                name: 'createCharacter',
                meta: {
                    title: '新增角色',
                    activeMenu: '/createCharacter',
                },
                component: () => import('../views/BackstageManagement/Character/CreateCharacter.vue'),
            },
            {
                path: '/editCharacter',
                name: 'editCharacter',
                meta: {
                    title: '編輯角色',
                    activeMenu: '/createCharacter',
                },
                component: () => import('../views/BackstageManagement/Character/EditCharacter.vue'),
            },
            {
                path: '/tgAlertList',
                name: 'tgAlertList',
                meta: {
                    title: '告警設定',
                },
                component: () => import('../views/BackstageManagement/TgAlert/TgAlertList.vue'),
            },
            {
                path: '/createTgAlert',
                name: 'createTgAlert',
                meta: {
                    title: '新增告警',
                    activeMenu: '/tgAlertList',
                },
                component: () => import('../views/BackstageManagement/TgAlert/CreateTgAlert.vue'),
            },
            {
                path: '/editTgAlert',
                name: 'editTgAlert',
                meta: {
                    title: '編輯告警',
                    activeMenu: '/tgAlertList',
                },
                component: () => import('../views/BackstageManagement/TgAlert/EditTgAlert.vue'),
            },
            {
                path: '/merchantAuthorityList',
                name: 'merchantAuthorityList',
                meta: {
                    title: '商戶權限列表',
                },
                component: () => import('../views/BackstageManagement/MerchantAuthority/MerchantAuthorityList.vue'),
            },
            {
                path: '/generalTable',
                name: 'generalTable',
                meta: {
                    title: '域名對接總表',
                },
                component: () => import('../views/ReportManagement/GeneralTable.vue'),
            },
            {
                path: '/salesCommission',
                name: 'salesCommission',
                meta: {
                    title: 'Clro銷售額與佣金(經銷)',
                },
                component: () => import('../views/ReportManagement/SalesCommission.vue'),
            },
            {
                path: '/renewalExpires',
                name: 'renewalExpires',
                meta: {
                    title: '續約到期列表',
                },
                component: () => import('../views/ReportManagement/RenewalExpires/RenewalExpires.vue'),
            },
            {
                path: '/weekReport',
                name: 'weekReport',
                meta: {
                    title: '週報表',
                },
                component: () => import('../views/ReportManagement/WeekReport.vue'),
            },
            {
                path: '/domainMarkList',
                name: 'domainMarkList',
                meta: {
                    title: '網域標記列表',
                },
                component: () => import('../views/Domain/DomainMark/DomainMarkList.vue'),
            },
            {
                path: '/createDomainMark',
                name: 'createDomainMark',
                meta: {
                    title: '新增網域標記列表',
                },
                component: () => import('../views/Domain/DomainMark/CreateDomainMark.vue'),
            },
            {
                path: '/editDomainMark',
                name: 'editDomainMark',
                meta: {
                    title: '編輯網域標記列表',
                },
                component: () => import('../views/Domain/DomainMark/EditDomainMark.vue'),
            },
            /** 目前拿掉匯率表的部分 */
            // {
            //     path: '/exchangeRateList',
            //     name: 'exchangeRateList',
            //     meta: {
            //         title: '匯率表',
            //     },
            //     component: () => import('../views/BackstageManagement/ExchangeRateTable/ExchangeRateList.vue'),
            // },
            // {
            //     path: '/createExchangeRate',
            //     name: 'createExchangeRate',
            //     meta: {
            //         title: '新增匯率表',
            //     },
            //     component: () => import('../views/BackstageManagement/ExchangeRateTable/CreateExchangeRate.vue'),
            // },
            // {
            //     path: '/editExchangeRate',
            //     name: 'editExchangeRate',
            //     meta: {
            //         title: '編輯匯率表',
            //     },
            //     component: () => import('../views/BackstageManagement/ExchangeRateTable/EditExchangeRate.vue'),
            // },
            {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "404" */
                        '../views/404.vue'
                    ),
            },
            {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "403" */
                        '../views/403.vue'
                    ),
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登录',
        },
        component: () =>
            import(
                /* webpackChunkName: "login" */
                '../views/Login.vue'
            ),
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

//判斷有無權限
const checkRouter = (weblink) => {
    let canSee = 200
    let data = {
        web_link: weblink,
    }
    return new Promise((resolve) => {
        adminPermission(data).then((res) => {
            if (res.code == 200) {
                canSee = 200
            } else if (res.code == 403) {
                canSee = 403
            } else if (res.code == 404) {
                canSee = 404
            } else if (res.code == 500) {
                canSee = 500
            }
            resolve(canSee)
        })
    })
}

router.beforeEach((to, from, next) => {
    // 看cookie 裡面有沒有token, 沒有的話返回login頁面
    let token = getCookie('__tk')
    if (!token && to.path !== '/login') {
        next('/login')
    } else {
        if (to.path !== '/403' && to.path !== '/404' && to.path !== '/login') {
            checkRouter(to.path.substr(1)).then((weblink) => {
                if (weblink == 200) {
                    next()
                } else if (weblink == 403) {
                    next('/403')
                } else if (weblink == 404) {
                    next('/404')
                } else if (weblink == 500) {
                    next(from.path)
                }
                // console.log(weblink)
                // next()
            })
        } else {
            next()
        }
    }
})

export default router
